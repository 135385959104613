<div class="container-loading" *ngIf="loadingType === 'bars'">
   <div class="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
   </div>
   {{ 'LOADING-BARS.TITLE' | translate }}
</div>

<div *ngIf="loadingType === 'spinner'" class="lds-roller">
   <div></div>
   <div></div>
   <div></div>
   <div></div>
   <div></div>
   <div></div>
   <div></div>
   <div></div>
</div>
