<header class="header" [class.header--active]="active">
   <button aria-label="abrir/fechar menu" class="header__toggle" (click)="toggle.emit(!active)">
      <span class="icon-menu"></span>
   </button>

   <div class="header__actions">
      <div class="header__actions-control">
         <kavalli-select
            (outEvent)="getEvent($event)"
            [dataOptions]="dataOptions"
            type="single"
            [selectedValue]="selectedProject"
         ></kavalli-select>
      </div>
      <button
         aria-label="comentários"
         [menu]="comments"
         (click)="toggleComments = !toggleComments"
         overlayPlacement="bottomRight"
      >
         <span class="icon-comments-regular"></span>
      </button>
      <button
         aria-label="notificações"
         [menu]="notifications"
         (click)="toggleNotifications = !toggleNotifications"
         overlayPlacement="bottomRight"
      >
         <span class="icon-bell"></span>
      </button>
   </div>
</header>

<ng-template #comments>
   <ul>
      <li>comentario 1</li>
      <li>comentario 2</li>
   </ul>
</ng-template>
<ng-template #notifications>
   <ul>
      <li>notifications 1</li>
      <li>notifications 2</li>
   </ul>
</ng-template>
