import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CountriesService } from '@services/countries-service.service';
import { Subscription } from 'rxjs';
import { AsYouType } from 'libphonenumber-js';

interface Country {
  name: string;
  img: string;
  idd: string;
  continent?: string;
  code?: string;
}

@Component({
  selector: 'telphone-input',
  templateUrl: './telphone-input.component.html',
  styleUrls: ['./telphone-input.component.scss']
})
export class TelphoneInputComponent implements OnInit, OnDestroy {
  constructor(private countriesService: CountriesService) {}
  public countries: Country[];
  public selectedCountry: any;
  public subscription: Subscription[] = []
  public valueFormatted: any;

  @Input() form: FormGroup;
  @Input() inputControlName: string;
  @Input() dropdownControlName: string;
  @Input() label: string;
  @Output() dataOut: EventEmitter<any> = new EventEmitter<any>();

  get inputControl() {
    return this.form.get(this.inputControlName) as FormControl;
  }

  get dropdownControl() {
    return this.form.get(this.dropdownControlName) as FormControl;
  }
  
  ngOnDestroy(): void {
    this.subscription.forEach(s => s.unsubscribe);
  }

  ngOnInit(): void  {
    this.getCountries();
    this.formatInitialValue();
  }

  formatInitialValue() {
    this.inputControl.valueChanges.subscribe((inputValue) => {
      if (inputValue && !inputValue.startsWith('+')) {
        this.valueFormatted = '+' + inputValue;
      } else {
        this.valueFormatted = new AsYouType().input(inputValue);
      }
  
      if (this.inputControl.value !== this.valueFormatted) {
        this.inputControl.setValue(this.valueFormatted, { emitEvent: false });
      }

      this.updateCountryFlag(inputValue);
    });
  }

  updateCountryFlag(inputValue: string) {
    let cleanedInput = inputValue.replace(/\s+/g, '');
    let potentialCountryCode = cleanedInput.length > 1 ? cleanedInput.substring(0, 3) : cleanedInput;

    let matchedCountry = this.countries.find(country => 
      potentialCountryCode.startsWith(country.idd)
    );

    if (matchedCountry) {
      this.selectedCountry = matchedCountry;
    }
  }
  
  getCountries() {
    this.subscription.push(
      this.countriesService
      .getCountries()
      .subscribe({
        next: (data) => {
          this.countries = Object.values(data).map((country: any) => ({
            name: country.name,
            img: country.img,
            idd: country.idd,
            code: country.code
          }));
          this.setValueDropdown();
          this.dataOut.emit(this.countries)
        }
      })
    )
  }

  setValueDropdown() {
    if (this.dropdownControlName === 'idd') {
      this.form.valueChanges.subscribe((e) => {
        this.selectedCountry = this.countries.find((el: any) => el.name === e.idd.name)
      })
    }
  }
  
}
