<ng-container *ngIf="data.typeModal === 'schedule'">
   <base-dialog title="{{ title }}">
      <div class="schedule-container">
         <main class="schedule-container__wrapper">
            <div class="separator">
               <div class="line1"></div>
               <span class="text">Agenda</span>
               <div class="line2 pre-title"></div>
            </div>
            <div class="schedule-container__wrapper-box">
               <div class="heading">
                  <p class="heading__description">
                     Início:
                     <span class="heading__description-value">
                        {{ this.convertDateApiToDisplay(data?.item?.startDate) }}</span
                     >
                  </p>
                  <p *ngIf="data?.item?.endDate" class="heading__description">
                     Fim:
                     <span class="heading__description-value">
                        {{ this.convertDateApiToDisplay(data?.item?.endDate) }}</span
                     >
                  </p>
                  <p *ngIf="data?.item.endDate" class="heading__description">
                     Hora iniciada:
                     <span class="heading__description-value"> {{ data?.item?.startTime }}</span>
                  </p>
               </div>
               <div class="body">
                  <div class="body__columns">
                     <p>Nome da Agenda:</p>
                     <span>{{ data?.item?.name }}</span>
                  </div>
                  <div class="body__columns">
                     <p>Endereço:</p>
                     <span>{{ data?.item?.address }}</span>
                  </div>
                  <div class="body__columns">
                     <p>Status Agenda:</p>
                     <span
                        [class.badge--inactive]="data?.item?.status === 'inactive'"
                        class="badge"
                        >{{ data?.item?.status === 'active' ? 'Active' : 'Inactive' }}</span
                     >
                  </div>
                  <div class="body__columns">
                     <p>Recorrente:</p>
                     <span>{{ data?.item?.recurring ? 'Sim' : 'Não' }}</span>
                  </div>
               </div>
            </div>
            <div class="separator">
               <div class="line1"></div>
               <span class="text">Customer</span>
               <div class="line2 pre-title"></div>
            </div>
            <div class="schedule-container__wrapper-box">
               <div class="body">
                  <div class="body__columns">
                     <p>Nome:</p>
                     <span>{{ data?.item?.customer.name }}</span>
                  </div>
                  <div class="body__columns">
                     <p>Número para contato:</p>
                     <span
                        ><a [href]="'tel:+' + data?.item?.customer.contacts">{{
                           data?.item?.customer.contacts
                        }}</a></span
                     >
                  </div>
                  <div class="body__columns">
                     <p>Endereço:</p>
                     <span>{{ data?.item?.customer.address }}</span>
                  </div>
               </div>
            </div>
            <div class="separator" *ngIf="data?.item?.users.length > 0">
               <div class="line1"></div>
               <span class="text">Usuários</span>
               <div class="line2 pre-title"></div>
            </div>
            <div class="schedule-container__wrapper-box" *ngIf="data?.item?.users.length > 0">
               <div class="wrappers">
                  <div *ngFor="let item of data?.item?.users" class="wrappers__content">
                     <p>
                        Nome: <span>{{ item.user.name }}</span>
                     </p>
                     <p>
                        Telefone: <a [href]="'tel:+' + item.phone">{{ item.user.phone }}</a>
                     </p>
                  </div>
               </div>
            </div>
            <div class="separator mt-1">
               <div class="line1"></div>
               <span class="text">Dias da Semana</span>
               <div class="line2 pre-title"></div>
            </div>
            <div class="schedule-container__wrapper-box">
               <ul class="container" *ngIf="data?.item?.weekdays; else notWeeks">
                  <li class="days" *ngFor="let day of weekDays" [ngClass]="getClass(day.value)">
                     {{ day.name }}
                  </li>
               </ul>
               <ng-template #notWeeks>
                  <ul class="container">
                     <li class="days day-inactive" *ngFor="let day of weekDays">
                        {{ day.name }}
                     </li>
                  </ul>
               </ng-template>
            </div>
         </main>
      </div>
   </base-dialog>
</ng-container>

<ng-container *ngIf="data.typeModal === 'timesheet'">
   <base-dialog title="{{ title }}">
      <div class="schedule-container">
         <main class="schedule-container__wrapper">
            <div class="separator">
               <div class="line1"></div>
               <span class="text">Timesheet</span>
               <div class="line2 pre-title"></div>
            </div>
            <div
               *ngIf="data.item; else withoutTimesheet"
               class="schedule-container__wrapper-box overlay"
            >
               <div class="body events" *ngFor="let timesheet of data?.item">
                  <div class="body__columns">
                     <p>Status</p>
                     <span>{{ timesheet?.action }}</span>
                  </div>
                  <div class="body__columns">
                     <p>Foto evidencia:</p>
                     <img width="120" [src]="timesheet.evidence" (click)="openPhoto(timesheet.evidence)" alt="Foto de evidência" />
                  </div>
                  <div class="body__columns">
                     <p>Data:</p>
                     <span>{{ this.convertDateApiToDisplay(timesheet?.timestamp) }}</span>
                  </div>
               </div>
            </div>
            <ng-template #withoutTimesheet>
               <div class="box-item">
                  <p>Sem timesheet</p>
               </div>
            </ng-template>
         </main>
      </div>
   </base-dialog>
</ng-container>
