import { Component, Input, OnInit } from '@angular/core';
import { Stats } from '@interfaces/geolocalization.interface';

@Component({
  selector: 'kavalli-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {
  @Input() dataTags: Stats
}
