<form [formGroup]="form" class="kavalli-multi-select-form">
   <label>{{ labelName }}</label>
   <div class="kavalli-multi-select-form__content">
      <p-multiSelect
         (click)="animatedArrow()"
         [options]="optionsData"
         [formControlName]="controlName"
         optionLabel="name"
         [placeholder]="placeholder"
         [filter]="true"
      >
      </p-multiSelect>
      <arrow
         class="kavalli-multi-select-form__content-arrow"
         [type]="'bottom'"
         [active]="arrowToggle"
      ></arrow>
   </div>
   <small *ngIf="isRequiredInvalid" class="error-message">{{
      'ERRORS.REQUIRED' | translate
   }}</small>
</form>
