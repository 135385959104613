<section
   class="kavalli-table"
   *ngIf="!hasGeolocation; else tableGeolocation"
   [@slideAnimation]="tableType !== 'stats' ? 'notActive' : 'active'"
>
   <p-table
      #kavalliTable
      [value]="customers"
      [paginator]="true"
      [rowsPerPageOptions]="[5, 10, 25, 50]"
      [globalFilterFields]="filterName"
      [rows]="5"
      responsiveLayout="stack"
      dataKey="id"
      [breakpoint]="'768px'"
   >
      <!-- // Usando input de pesquisa com api -->
      <ng-template pTemplate="caption" *ngIf="hasSearchInput; else defaultInput">
         <div class="flex">
            <span class="p-input-icon-left ml-auto search-header">
               <span class="icon-search icon"></span>
               <input
                  pInputText
                  type="text"
                  (input)="outValue($event.target)"
                  placeholder="{{ 'SEARCH-PLACEHOLDER.PLACEHOLDER' | translate }}"
               />
            </span>
         </div>
      </ng-template>
      <!-- // Usando input de pesquisa do front -->
      <ng-template #defaultInput>
         <ng-template pTemplate="caption">
            <div class="flex">
               <span class="p-input-icon-left ml-auto search-header">
                  <span class="icon-search icon"></span>
                  <input
                     pInputText
                     type="text"
                     (input)="kavalliTable.filterGlobal($any($event.target)?.value, 'contains')"
                     placeholder="{{ 'SEARCH-PLACEHOLDER.PLACEHOLDER' | translate }}"
                  />
               </span>
            </div>
         </ng-template>
      </ng-template>

      <ng-template pTemplate="header">
         <tr>
            <th
               [ngStyle]="{ width: column === 'Foto' || 'Id' ? '5%' : '25%' }"
               *ngFor="let column of columns"
               style="width: 25%"
            >
               {{ column }}
            </th>
         </tr>
      </ng-template>
      <!-- ---- Users --- -->
      <ng-template *ngIf="tableType === 'users'" pTemplate="body" let-customer>
         <tr>
            <td>{{ customer.id }}</td>
            <td *ngIf="customer.photo; else defaultPhoto">
               <img [src]="customer.photo" style="border-radius: 50%" width="40px;" />
            </td>
            <ng-template #defaultPhoto>
               <td style="width: 5%">
                  <img src="assets/imgs/avatar.svg" width="40px" style="border-radius: 50%" />
               </td>
            </ng-template>
            <td>{{ customer.name }}</td>
            <td>{{ customer.clientUser[0].role === 'standard' ? 'USER' : 'ADMIN' }}</td>
            <td>
               <a [href]="'mailto:' + customer.email">{{ customer.email }}</a>
            </td>
            <td>
               <a [href]="'tel:+' + customer.phone">{{ customer.phone }}</a>
            </td>
            <td>{{ maskedBirthdate(customer.birthDate) }}</td>
            <td>
               <div class="row-inline">
                  <span
                     *ngIf="customer.phone && customer.email"
                     [ngClass]="{
                        'color-primary-button--disabled': !customer.phone && !customer.email
                     }"
                     class="icon-key color-primary-button"
                     (click)="eventItemClicked(customer, 'generate')"
                     [tooltip]="
                        customer.phone && customer.email
                           ? 'Senha provisória'
                           : 'Cadastrar Email e Senha'
                     "
                  ></span>
                  <img
                     *ngIf="customer.clientUser[0].role !== 'admin'"
                     role="button"
                     src="assets/imgs/user-block.svg"
                     (click)="eventItemClicked(customer, 'disable')"
                     tooltip="Desabilitar usuário"
                     class="edit-button"
                  />
                  <ng-container >
                     <img
                        role="button"
                        (click)="eventItemClicked(customer, 'become')"
                        tooltip="Tornar Admin"
                        width="18"
                        height="18"
                        src="assets/imgs/user-admin.svg"
                        class="button-action"
                     />
                     <img
                        *ngIf="customer.clientUser[0].role === 'admin'"
                        role="button"
                        (click)="eventItemClicked(customer, 'reset')"
                        tooltip="Reset senha Admin"
                        width="18"
                        height="18"
                        src="assets/imgs/badlock-open.svg"
                        class="button-action"
                     />
                  </ng-container>
               </div>
            </td>
         </tr>
      </ng-template>
      <!-- ---- Schedule --- -->
      <ng-template *ngIf="tableType === 'schedule'" pTemplate="body" let-customer>
         <tr>
            <td>{{ customer.id }}</td>
            <td>{{ customer.name }}</td>
            <td>{{ customer.address }}</td>
            <td>{{ customer.customer.name ? customer.customer.name : 'N/A' }}</td>
            <td>
               <mat-checkbox [(ngModel)]="customer.recurring" [disabled]="true"></mat-checkbox>
            </td>
            <td [ngSwitch]="customer.status">
               <p-tag
                  [rounded]="true"
                  *ngSwitchCase="'active'"
                  severity="success"
                  value="Active"
               ></p-tag>
               <p-tag
                  [rounded]="true"
                  *ngSwitchCase="'inactive'"
                  [style]="{ background: '#727272' }"
                  value="Inactive"
               ></p-tag>
            </td>
            <td>
               {{ customer.startDate }}
            </td>
            <td>
               <div class="row-inline">
                  <span
                     role="button"
                     (click)="eventItemClicked(customer, 'edit')"
                     tooltip="Editar agenda"
                     class="icon-pencil color-primary-button"
                  ></span>
                  <!-- <span
                     role="button"
                     (click)="eventItemClicked(customer, 'cancel')"
                     tooltip="Cancelar"
                     class="icon-cancel-circle color-primary-button"
                  ></span> -->
                  <span
                     role="button"
                     (click)="displayInformationSchedule(customer, 'schedule')"
                     tooltip="Ver dados"
                     class="icon-info-circled color-primary-button"
                  ></span>
               </div>
            </td>
         </tr>
      </ng-template>

      <!-- ---- Schedule Events --- -->
      <ng-template
         *ngIf="tableType === 'schedule-events'"
         pTemplate="body"
         let-customer
         let-expanded="expanded"
      >
         <tr>
            <td style="width: 4%">
               <button
                  type="button"
                  pButton
                  pRipple
                  [pRowToggler]="customer"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
               ></button>
            </td>
            <td>{{ customer.id }}</td>
            <td>{{ customer.schedule.name }}</td>
            <td [ngSwitch]="customer.status">
               <p-tag
                  [rounded]="true"
                  *ngSwitchCase="'finished'"
                  [style]="{ background: '#28a745' }"
                  value="Finished"
               ></p-tag>
               <p-tag
                  [rounded]="true"
                  *ngSwitchCase="'canceled'"
                  [style]="{ background: '#dc3545' }"
                  value="Canceled"
               ></p-tag>
               <p-tag
                  [rounded]="true"
                  *ngSwitchCase="'queued'"
                  [style]="{ background: '#ffc107' }"
                  value="Queued"
               ></p-tag>
               <p-tag
                  [rounded]="true"
                  *ngSwitchCase="'in-progress'"
                  [style]="{ background: '#007bff' }"
                  value="In-progress"
               ></p-tag>
               <p-tag
                  [rounded]="true"
                  *ngSwitchCase="'missed'"
                  [style]="{ background: '#dc3545' }"
                  value="Missed"
               ></p-tag>
            </td>
            <td>{{ customer.schedule.address }}</td>
            <td>
               {{ customer.startDateTime | convertStamp }}
            </td>
         </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-event let-expanded="expanded">
         <tr>
            <td colspan="7">
               <div class="p-3">
                  <p-table [value]="event?.users" dataKey="id">
                     <ng-template pTemplate="header">
                        <tr>
                           <th *ngFor="let subColumn of subColumns">{{ subColumn }}</th>
                        </tr>
                     </ng-template>
                     <ng-template pTemplate="body" let-event1 let-subExpanded="expanded">
                        <tr>
                           <td *ngIf="event1.user.photo; else defaultPhoto">
                              <img
                                 [src]="event1.user.photo"
                                 style="border-radius: 50%"
                                 width="40px;"
                              />
                           </td>
                           <ng-template #defaultPhoto>
                              <td style="width: 5%">
                                 <img
                                    src="assets/imgs/avatar.svg"
                                    width="40px"
                                    style="border-radius: 50%"
                                 />
                              </td>
                           </ng-template>
                           <td>
                              {{ event1.user.id }}
                           </td>
                           <td>
                              {{ event1.user.name }}
                           </td>
                           <td>
                              {{ event1.user.birthDate }}
                           </td>
                           <td>
                              <a [href]="'tel:+' + event1.user.phone">{{ event1.user.phone }}</a>
                           </td>
                           <td>
                              <a [href]="'mailto:' + event1.user.email">{{ event1.user.email }}</a>
                           </td>
                           <td [ngSwitch]="event1.status">
                              <p-tag
                                 [rounded]="true"
                                 *ngSwitchCase="'finished'"
                                 [style]="{ background: '#28a745' }"
                                 value="Finished"
                              ></p-tag>
                              <p-tag
                                 [rounded]="true"
                                 *ngSwitchCase="'canceled'"
                                 [style]="{ background: '#dc3545' }"
                                 value="Canceled"
                              ></p-tag>
                              <p-tag
                                 [rounded]="true"
                                 *ngSwitchCase="'queued'"
                                 [style]="{ background: '#ffc107' }"
                                 value="Queued"
                              ></p-tag>
                              <p-tag
                                 [rounded]="true"
                                 *ngSwitchCase="'in-progress'"
                                 [style]="{ background: '#007bff' }"
                                 value="In-progress"
                              ></p-tag>
                              <p-tag
                                 [rounded]="true"
                                 *ngSwitchCase="'missed'"
                                 [style]="{ background: '#dc3545' }"
                                 value="Missed"
                              ></p-tag>
                           </td>
                           <td>
                              <span
                                 role="button"
                                 [ngClass]="{
                                    'color-primary-button--disabled':
                                       event1?.user?.timesheets.length < 1
                                 }"
                                 (click)="
                                    displayInformationSchedule(
                                       event1?.user?.timesheets,
                                       'timesheet'
                                    )
                                 "
                                 [tooltip]="
                                    event1?.user?.timesheets.length < 1
                                       ? 'Sem timesheets'
                                       : 'Ver timesheets'
                                 "
                                 class="icon-info-circled color-primary-button"
                              ></span>
                           </td>
                        </tr>
                     </ng-template>
                  </p-table>
               </div>
            </td>
         </tr>
      </ng-template>
   </p-table>
</section>

<!-- // Table Exclusiva para Geolocalização
-- Usamos outra variante de table porque não aceita 2 tables com subrows -->
<ng-template #tableGeolocation>
   <section class="kavalli-table">
      <p-table
         #kavalliTable
         [value]="customers"
         [paginator]="true"
         [rowsPerPageOptions]="[5, 10, 25, 50]"
         [globalFilterFields]="filterName"
         [rows]="5"
         responsiveLayout="stack"
         dataKey="id"
         [breakpoint]="'768px'"
      >
         <ng-template pTemplate="header">
            <tr>
               <th
                  [ngStyle]="{ width: column === 'Foto' || 'Id' ? '5%' : '25%' }"
                  *ngFor="let column of columns"
                  style="width: 25%"
               >
                  {{ column }}
               </th>
            </tr>
         </ng-template>
         <!-- // Usando input de pesquisa com api -->
         <ng-template pTemplate="caption" *ngIf="hasSearchInput; else defaultInput">
            <div class="flex">
               <span class="p-input-icon-left ml-auto search-header">
                  <span class="icon-search icon"></span>
                  <input
                     pInputText
                     type="text"
                     (input)="outValue($event.target)"
                     placeholder="{{ 'SEARCH-PLACEHOLDER.PLACEHOLDER' | translate }}"
                  />
               </span>
            </div>
         </ng-template>
         <!-- // Usando input de pesquisa do front -->
         <ng-template #defaultInput>
            <ng-template pTemplate="caption">
               <div class="flex">
                  <span class="p-input-icon-left ml-auto search-header">
                     <span class="icon-search icon"></span>
                     <input
                        pInputText
                        type="text"
                        (input)="kavalliTable.filterGlobal($any($event.target)?.value, 'contains')"
                        placeholder="{{ 'SEARCH-PLACEHOLDER.PLACEHOLDER' | translate }}"
                     />
                  </span>
               </div>
            </ng-template>
         </ng-template>
         <ng-template pTemplate="body" let-customer let-expanded="expanded">
            <tr *ngIf="customer.geolocations.length > 0">
               <td style="width: 4%">
                  <button
                     type="button"
                     pButton
                     pRipple
                     [pRowToggler]="customer"
                     class="p-button-text p-button-rounded p-button-plain"
                     [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                     *ngIf="customer.geolocations.length > 0; else noData"
                  ></button>
                  <ng-template #noData>
                     <button
                        type="button"
                        pButton
                        pRipple
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="'pi pi-ban'"
                     ></button>
                  </ng-template>
               </td>
               <td>{{ customer.id }}</td>
               <td>{{ customer.name }}</td>
               <td>
                  <a [href]="'tel:+' + customer.phone">{{ customer.phone }}</a>
               </td>
               <td
                  *ngIf="
                     customer.geolocations[customer.geolocations.length - 1]?.address
                        ?.country_code === 'br';
                     else otherCountry
                  "
               >
                  {{ customer.geolocations[customer.geolocations.length - 1]?.address?.city }}
                  |
                  {{ customer.geolocations[customer.geolocations.length - 1]?.address?.road }}
               </td>
               <ng-template #otherCountry>
                  <td>
                     {{ customer.geolocations[customer.geolocations.length - 1]?.address?.road }} |
                     {{ customer.geolocations[customer.geolocations.length - 1]?.address?.state }}
                  </td>
               </ng-template>
            </tr>
         </ng-template>
         <ng-template pTemplate="rowexpansion" let-other let-expanded="expanded">
            <tr *ngIf="other?.geolocations?.length > 0">
               <td colspan="7">
                  <div class="p-3">
                     <p-table
                        [paginator]="true"
                        [rowsPerPageOptions]="[5, 10, 25, 50]"
                        [rows]="5"
                        dataKey="id"
                        [value]="other?.geolocations"
                        dataKey="id"
                     >
                        <ng-template pTemplate="header">
                           <tr>
                              <th *ngFor="let subColumn of subColumns">{{ subColumn }}</th>
                           </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-other1 let-subExpanded="expanded">
                           <tr>
                              <td>{{ convertTimeOnClock(other1.createdAt) }}</td>
                              <td>{{ convertTime(other1.timestamp) }}</td>
                              <td>{{ other1.longitude }}, {{ other1.latitude }}</td>
                              <td *ngIf="other1.address.coountry_code === 'br'; else gringo">
                                 {{ other1.address.city }} - {{ other1.address.road }}
                              </td>
                              <ng-template #gringo>
                                 <td>
                                    {{ other1.address.house_number }} {{ other1.address.road }}
                                    {{ other1.address.hamlet }}, {{ other1.address.state }}
                                    {{ other1.address.postcode }}, {{ other1.address.country }}
                                 </td>
                              </ng-template>
                           </tr>
                        </ng-template>
                     </p-table>
                  </div>
               </td>
            </tr>
         </ng-template>
      </p-table>
   </section>
</ng-template>
