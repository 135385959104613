import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private readonly apiUrl = environment.apiUrl;
  constructor(private httpClient: HttpClient) { }

  public getProjects(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}clients-users/client/get`);
  }

  public selectProject(idClient: string): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}clients-users/select/${idClient}`);
  }
}
