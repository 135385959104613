import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "@core/pages/not-found/not-found.component";
import { PrivacyPolicyComponent } from "@core/pages/policys/privacy-policy/privacy-policy.component";
import { AuthenticatedGuard } from "@guards/authenticated.guard";
import { DisableGuardGuard } from "@guards/disable.guard";

const routes: Routes = [
   { path: "", redirectTo: "stats", pathMatch: "full" },
   {
      path: "stats",
      loadChildren: () => import("./core/pages/stats/stats.module").then(m => m.StatsModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: "users",
      loadChildren: () => import("./core/pages/users/users.module").then(m => m.UsersModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: "update-user",
      loadChildren: () =>
         import("./core/pages/users-update/users-update.module").then(m => m.UsersUpdateModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: "login",
      loadChildren: () => import("./core/pages/auth/auth.module").then(m => m.AuthModule),
      canActivate: [DisableGuardGuard],
   },
   {
      path: "users/new",
      loadChildren: () =>
         import("./core/pages/new-user/new-user.module").then(m => m.NewUserModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: "schedule",
      loadChildren: () =>
         import("./core/pages/schedule/schedule.module").then(m => m.ScheduleModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: "schedule/new-schedule",
      loadChildren: () =>
         import("./core/pages/schedule-new/schedule-new.module").then(m => m.ScheduleNewModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: "schedule/events",
      loadChildren: () =>
         import("./core/pages/schedule-events/schedule-events.module").then(m => m.ScheduleEventsModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: "reports",
      loadChildren: () =>
         import("./core/pages/reports/reports.module").then(m => m.ReportsModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: "reports/geolocalization",
      loadChildren: () =>
         import("./core/pages/geolocalization/geolocalization.module").then(m => m.GeolocalizationModule),
      canActivate: [AuthenticatedGuard],
   },
   {
      path: 'recuperar-senha',
      loadChildren: () =>
         import("./core/pages/password-recovery/password-recovery.module").then(
            m => m.PasswordRecoveryModule,
         ),
   },
   {path: '404', component: NotFoundComponent, data: { disabledPermission: true },},
   { path: '**', redirectTo: "404", pathMatch: "full" },
   { path: 'privacy-policy', component: PrivacyPolicyComponent },
]

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule],
})
export class AppRoutingModule {}
