import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectsService } from '@services/projects.service';
import { Subscription } from 'rxjs';
import { ToastComponent } from '../toast/toast.component';
import { finalize, first, map } from 'rxjs/operators';
import { LoadingService } from '@shared/services/dialog/loading.service';
import { VoidInterface } from '@interfaces/generic.interface';
import { AdminResponse } from '@interfaces/admin-response.interface';
import { UserService } from '@services/user.service';
import { UserDataService } from '@shared/services/userdata.service';
import { NavigationEnd, Router } from '@angular/router';
import { ELocalStorage } from '@enums/localstorage.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
   constructor(
      private projectsService: ProjectsService,
      private snackBar: MatSnackBar,
      private loading: LoadingService,
      private userService: UserService,
      private userDataService: UserDataService,
      private router: Router
   ) {}
   public subscriptions: Subscription[] = [];
   @Input() active: boolean;
   @Output() toggle = new EventEmitter<boolean>()
   toggleComments: boolean = false
   toggleNotifications: boolean = false
   dataOptions = [];
   inRoute: boolean;
   selectedProject: {id: string | number, name: string}

   public validaFormGroup: FormGroup = new FormGroup({
      name: new FormControl(""),
   })
   
   ngOnDestroy(): void {
      this.subscriptions.forEach(s => s.unsubscribe);
   }

   ngOnInit(): void {
      this.verifyRoute();
      this.initialProject();
   }

   verifyRoute() {
      this.subscriptions.push(
         this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
               const paths = [
                  '/login',
                  '/checkout',
                  '/404',
                  '/privacy-policy'
               ]
               paths.includes(event.url) ? null : this.displayProjects()
            }
          })
      );
   }

   displayProjects() {
      this.subscriptions.push(
         this.projectsService.getProjects().subscribe({
            next: (response: any) => {
               this.dataOptions = response.map((item: any) => ({
                  id: item.client.id,
                  name: item.client.name
               }))
            },
            error: (error: any) => {
               const data: any = {
                  title: "Error",
                  text: error.error.message,
                  type: "error",
                  icon: "cancel-circle",
               };
               this.openToast(data);
            }
         })
      )
   }

   getEvent(ev: MatSelectChange) {
      this.choiceProject(ev.value)
   }

   initialProject() {
      let projectSelected: any = localStorage.getItem(ELocalStorage.USER_DATA);
      const projectSelectedParsed = JSON.parse(projectSelected)?.selectedClient
      this.selectedProject = {
         id: projectSelectedParsed?.id,
         name: projectSelectedParsed?.name
      }
   }

   updateUserData() {
      this.loading.setLoading(true)
      this.subscriptions.push(
         this.userService
            .getAdmindata()
            .pipe(first())
            .pipe(finalize(() => this.loading.setLoading(false)))
            .subscribe({
               next: (response: AdminResponse) => {
                  this.userDataService.setStorageUserData({
                     name: response.name,
                     lastLogin: response.lastLogin,
                     photo: response.photo,
                     role: response.role,
                     selectedClient: response.selectedClient
                  });
                  window.location.reload();
               },
               error: error => {
                  const data: any = {
                     title: "Error",
                     text: error.error.message,
                     type: "error",
                     icon: "cancel-circle",
                  };
                  this.openToast(data);
               },
            }),
      )
   }

   choiceProject(projectSelectedId: number) {
      this.loading.setLoading(true)
      this.subscriptions.push(
         this.projectsService
         .selectProject(projectSelectedId.toString())
         .pipe(
            finalize(() => {
               this.loading.setLoading(false)
            })
         )
         .subscribe({
            next: (_: VoidInterface) => {
               this.updateUserData();
            },
            error: (error: any) => {
               const data: any = {
                  title: "Error",
                  text: error.error.message,
                  type: "error",
                  icon: "cancel-circle",
               };
               this.openToast(data);
            }
         })
      )
   }

   openToast(data: any) {
      this.snackBar.openFromComponent(ToastComponent, {
         verticalPosition: "bottom",
         data: data,
         horizontalPosition: "right",
      });
   }

}
