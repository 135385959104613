<section class="generic-table">
   <table class="table">
      <thead class="table__head">
         <tr class="table__head-tr">
            <ng-content select="[head]"></ng-content>
         </tr>
      </thead>
      <tbody class="table__body">
         <ng-content select="[body]"></ng-content>
      </tbody>
   </table>
</section>
