import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService {
  private readonly apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  public updateAdminProfilePhoto(img: string): Observable<Array<string>>  {
   return this.httpClient.get<Array<string>>(`${this.apiUrl}admin-users/profile/photo?image=${img}`);
 }
}
