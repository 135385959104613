import { Component, Input, OnInit } from "@angular/core";
import { ELocalStorage } from "@enums/localstorage.enum";
import { ModalService } from "@shared/services/dialog/modal.service";
import { UserDataService } from "@shared/services/userdata.service";
import { DialogProfileImgComponent } from "../dialog-profile-img/dialog-profile-img.component";
import { UserService } from "@services/user.service";
import { NavMenuInterface } from "./routes";

@Component({
   selector: "sidemenu",
   templateUrl: "./sidemenu.component.html",
   styleUrls: ["./sidemenu.component.scss"],
})
export class SidemenuComponent implements OnInit {
   @Input() active: boolean;
   @Input() browserLang: string;
   @Input() navi18n: any;

   public routers: NavMenuInterface[] = [];
   profileImg: string | null
   constructor(
      public userDataService: UserDataService,
      private dialog: ModalService,
      private userService: UserService,
   ) {}

   ngOnInit(): void {
      const role = this.getRole() || [];
      for (const item of this.navi18n) {
         const findRole = role.routes?.find((e: any) => e === item.route || e === "*");
         if (findRole === "*") {
            this.routers = this.navi18n;
         } else if (findRole && findRole !== "*") {
            this.routers.push(item);
         }
      }
   }

   openCropper(){
      this.dialog
      .open(DialogProfileImgComponent, { panelClass: "cropper" })
   }

   getRole() {
      const userDataStr: any = localStorage.getItem(ELocalStorage.USER_DATA);
      const userData = JSON.parse(userDataStr);
      return userData?.role;
   }

   activeSubmenu(clickedRoute: NavMenuInterface) {
      this.routers.forEach((route) => {
          if (route.id === clickedRoute.id) {
              route.collapseMenu = !route.collapseMenu;
          } else {
              route.collapseMenu = false;
          }
      });
   }

   logOut() {
      this.userService.logout()
   }
}
