import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { DialogRef } from '@shared/services/dialog/dialog-refs.helper';
import { DIALOG_DATA } from '@shared/services/dialog/modal.service';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalImageComponent {
  constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: any) { }
}
