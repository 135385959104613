import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ModalService } from '@shared/services/dialog/modal.service'
import { DialogScheduleComponent } from '../dialog-schedule/dialog-schedule.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kavalli-table',
  templateUrl: './kavalli-table.component.html',
  styleUrls: ['./kavalli-table.component.scss'],
  animations: [
    trigger('slideAnimation', [
      state('notActive', style({ transform: 'translateX(0)', opacity: 1 })),
      state('active', style({})),
      transition('* => notActive', [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate('500ms ease-out')
      ]),
      transition('notActive => active', animate('500ms ease-in')),
      transition(':enter', animate('500ms ease-out')),
      transition(':leave', animate('500ms ease-in')),
    ])
  ]
})
export class KavalliTableComponent implements OnDestroy {
  public subscriptions: Subscription[] = []
  constructor(
    public router: Router, 
    private dialog: ModalService,
  ) {}

  @Input() customers: any;
  @Input() columns: string[];
  @Input() subColumns: string[];
  @Input() loadingTable: boolean;
  @Input() tableType: string;
  @Input() hasGeolocation: boolean = false;
  @Input() filterName!: any;
  @Input() hasSearchInput: boolean;
  
  @Output() outPutEventCustomer = new EventEmitter<any>();
  @Output() outSearch = new EventEmitter<any>();

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe)
  }

  eventItemClicked(customerItems: any, event?: any) {
    this.outPutEventCustomer.emit({customerItems, event});
  }

  convertTimeOnClock(time: string) {
    let clockFormated = moment(time).format("MM/DD/YYYY");
    return clockFormated;
  }

  maskedBirthdate(birth: string) {
    let birthFormated = moment(birth).format("MM/DD/YYYY")
    return birthFormated
  }

  convertTime(time: string) {
    const clock = moment(time);
    const timeFormated = clock.format("HH:mm A");
    return timeFormated
  }

  displayInformationSchedule(item: any, typeModal: string) {
    if (item) {
      this.dialog
       .open(DialogScheduleComponent, { data: {item: item, typeModal: typeModal}, panelClass: "schedule-dialog" })
    } else {
      return
    }
  }

  outValue(ev: any) {
    this.outSearch.emit(ev)
  }

}
