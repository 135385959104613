<ng-container *ngIf="data.typeDialog === 1">
   <div class="dialog-associate" *ngIf="steps === 1">
      <h3>{{ 'DIALOG-GENERIC.TYPE-1.TITLE-1' | translate }}</h3>
      <p>{{ data.item.name }} {{ 'DIALOG-GENERIC.TYPE-1.DESCRIPTION-ASSOCIATE' | translate }}</p>
      <button
         type="submit"
         [class.btn-loader--start]="isLoading"
         class="btn-loader"
         (click)="displayAssociate()"
      >
         {{ 'DIALOG-GENERIC.TYPE-1.ASSOCIATE' | translate }}
      </button>
   </div>

   <div class="dialog-associate" *ngIf="steps === 2">
      <h3 class="error-title">{{ errorData.error.message }}</h3>
      <button class="btn-loader" (click)="dialogRef.close()">OK</button>
   </div>
</ng-container>
<ng-container *ngIf="data.typeDialog === 2"></ng-container>
<ng-container *ngIf="data.typeDialog === 3"></ng-container>
