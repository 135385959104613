import { TelphoneInputComponent } from './components/telphone-input/telphone-input.component'
import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { FilterPipe } from '@pipes/filter.pipe'
import { HttpSafeImagePipe } from '@pipes/http-safe-image.pipe'
import { SafePipe } from '@pipes/safe.pipe'
import { StatusPipe } from '@pipes/status.pipe'
import player from 'lottie-web'
import { CurrencyMaskModule } from 'ng2-currency-mask'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { LottieModule } from 'ngx-lottie'
import { IConfig, NgxMaskModule } from 'ngx-mask'
import { NgxPaginationModule } from 'ngx-pagination'
import { AlertsComponent } from './components/alerts/alerts.component'
import { ArrowAnimatedComponent } from './components/arrow-animated/arrow-animated.component'
import { BaseDialogComponent } from './components/base-dialog/base-dialog.component'
import { CardComponent } from './components/card/card.component'
import { CategoryCardsComponent } from './components/category-cards/category-cards.component'
import { DeleteConfirmComponent } from './components/delete-confirm/delete-confirm.component'
import { DragDropFileComponent } from './components/drag-drop-file/drag-drop-file.component'
import { ErrorMessageComponent } from './components/error-message/error-message.component'
import { GenericConfirmComponent } from './components/generic-confirm/generic-confirm.component'
import { InputsComponent } from './components/inputs/inputs.component'
import { LoadingComponent } from './components/loading/loading.component'
import { LoadingSpinComponent } from './components/loading-spin/loading-spin.component'
import { NotFoundMessageComponent } from './components/not-found-message/not-found-message.component'
import { OptionsMenuComponent } from './components/options-menu/options-menu.component'
import { OptionsMenuDirective } from './components/options-menu/options-menu.directive'
import { OrdenationMenuComponent } from './components/ordenation-menu/ordenation-menu.component'
import { ReasonRejectionComponent } from './components/reason-rejection/reason-rejection.component'
import { SearchInputComponent } from './components/search-input/search-input.component'
import { SidebarStructureComponent } from './components/sidebar-structure/sidebar-structure.component'
import { SidemenuComponent } from './components/sidemenu/sidemenu.component'
import { TableComponent } from './components/table/table.component'
import { ToastComponent } from './components/toast/toast.component'
import { TransactionsTableComponent } from './components/transactions-table/transactions-table.component'
import { DropzoneDirective } from './directives/dropzone.directive'
import { TooltipModule } from './directives/tooltip/tooltip.module'
import { PaginationComponent } from './pagination/pagination.component'
import { BorderColorPipe } from './pipes/border-color.pipe'
import { CapitalizeCasePipe } from './pipes/capitalize-case.pipe'
import { EllipsisPipe } from './pipes/ellipsis.pipe'
import { IconPipe } from './pipes/icon.pipe'
import { SituationPipe } from './pipes/situation.pipe'
import { UrlChangesPipe } from './pipes/url-changes.pipe'
import { FreeInputsComponent } from './components/free-inputs/free-inputs.component'
import { MatSelectModule } from '@angular/material/select'
import { SelectComponent } from './components/select/select.component'
import { NgApexchartsModule } from 'ng-apexcharts'
import { ChartComponent } from './components/chart/chart.component'
import { TagsComponent } from './components/tags/tags.component'
import { KavalliTableComponent } from './components/kavalli-table/kavalli-table.component'
import { TableModule } from 'primeng/table'
import { SkeletonModule } from 'primeng/skeleton'
import { ListboxModule } from 'primeng/listbox'
import { DialogProfileImgComponent } from './components/dialog-profile-img/dialog-profile-img.component'
import { DropdownModule } from 'primeng/dropdown'
import { ImageCropperModule } from 'ngx-image-cropper'
import { SliderModule } from 'primeng/slider'
import { CalendarModule } from 'primeng/calendar'
import { DateInputComponent } from './components/date-input/date-input.component'
import { HeaderComponent } from './components/header/header.component'
import { CdkMenuModule } from '@angular/cdk/menu'
import { TagModule } from 'primeng/tag'
import { MultiSelectModule } from 'primeng/multiselect'
import { KavalliMultiSelectComponent } from './components/kavalli-multi-select/kavalli-multi-select.component'
import { GMapModule } from 'primeng/gmap'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { DialogScheduleComponent } from './components/dialog-schedule/dialog-schedule.component'
import { ConvertStampPipe } from './pipes/convert-stamp.pipe'
import { MatRadioModule } from '@angular/material/radio'
import { RadioComponent } from './components/radio/radio.component'
import { DialogGenericComponent } from './components/dialog-generic/dialog-generic.component'
import { DisableConfirmComponent } from './components/disable-confirm/disable-confirm.component';
import { ModalImageComponent } from './components/modal-image/modal-image.component';
import { DateMaskDirective } from './directives/date-mask.directive'

const maskConfig: Partial<IConfig> = {
   validation: false,
}

export function playerFactory() {
   return player
}
@NgModule({
   declarations: [
      HeaderComponent,
      SidemenuComponent,
      CardComponent,
      EllipsisPipe,
      CapitalizeCasePipe,
      SearchInputComponent,
      ArrowAnimatedComponent,
      OptionsMenuDirective,
      OptionsMenuComponent,
      TableComponent,
      FilterPipe,
      SituationPipe,
      IconPipe,
      BaseDialogComponent,
      DeleteConfirmComponent,
      SidebarStructureComponent,
      InputsComponent,
      FreeInputsComponent,
      ErrorMessageComponent,
      DragDropFileComponent,
      AlertsComponent,
      SafePipe,
      CategoryCardsComponent,
      DropzoneDirective,
      LoadingComponent,
      ToastComponent,
      PaginationComponent,
      LoadingSpinComponent,
      ReasonRejectionComponent,
      TransactionsTableComponent,
      BorderColorPipe,
      OrdenationMenuComponent,
      UrlChangesPipe,
      NotFoundMessageComponent,
      StatusPipe,
      HttpSafeImagePipe,
      GenericConfirmComponent,
      SelectComponent,
      ChartComponent,
      TagsComponent,
      KavalliTableComponent,
      DialogProfileImgComponent,
      TelphoneInputComponent,
      DateInputComponent,
      HeaderComponent,
      KavalliMultiSelectComponent,
      DialogScheduleComponent,
      ConvertStampPipe,
      RadioComponent,
      DialogGenericComponent,
      DisableConfirmComponent,
      ModalImageComponent,
      DateMaskDirective,
   ],
   imports: [
      CommonModule,
      RouterModule,
      ReactiveFormsModule,
      OverlayModule,
      NgxPaginationModule,
      ImageCropperModule,
      NgxMaskModule.forRoot(maskConfig),
      LottieModule.forRoot({ player: playerFactory }),
      TooltipModule,
      MatSnackBarModule,
      InfiniteScrollModule,
      CurrencyMaskModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatInputModule,
      MatNativeDateModule,
      MatSelectModule,
      NgApexchartsModule,
      TableModule,
      SkeletonModule,
      ListboxModule,
      SliderModule,
      CdkMenuModule,
      MatAutocompleteModule,
      DropdownModule,
      FormsModule,
      CalendarModule,
      TagModule,
      MultiSelectModule,
      GMapModule,
      MatCheckboxModule,
      MatRadioModule,
      TranslateModule.forChild(),
   ],
   exports: [
      HeaderComponent,
      SidemenuComponent,
      RouterModule,
      CardComponent,
      EllipsisPipe,
      CapitalizeCasePipe,
      SearchInputComponent,
      ArrowAnimatedComponent,
      OptionsMenuDirective,
      OptionsMenuComponent,
      TableComponent,
      NgxMaskModule,
      FilterPipe,
      SituationPipe,
      IconPipe,
      BaseDialogComponent,
      DeleteConfirmComponent,
      SidebarStructureComponent,
      InputsComponent,
      FreeInputsComponent,
      ErrorMessageComponent,
      DragDropFileComponent,
      AlertsComponent,
      SafePipe,
      CategoryCardsComponent,
      LoadingComponent,
      NgxPaginationModule,
      TooltipModule,
      ToastComponent,
      MatSnackBarModule,
      PaginationComponent,
      InfiniteScrollModule,
      LoadingSpinComponent,
      ReasonRejectionComponent,
      CurrencyMaskModule,
      TransactionsTableComponent,
      BorderColorPipe,
      OrdenationMenuComponent,
      NotFoundMessageComponent,
      StatusPipe,
      HttpSafeImagePipe,
      MatDatepickerModule,
      MatFormFieldModule,
      MatInputModule,
      MatNativeDateModule,
      ReactiveFormsModule,
      MatSelectModule,
      SelectComponent,
      ChartComponent,
      TagsComponent,
      KavalliTableComponent,
      ListboxModule,
      DialogProfileImgComponent,
      TelphoneInputComponent,
      DropdownModule,
      ImageCropperModule,
      CalendarModule,
      DateInputComponent,
      KavalliMultiSelectComponent,
      GMapModule,
      MatCheckboxModule,
      DialogScheduleComponent,
      MatRadioModule,
      RadioComponent,
      DialogGenericComponent,
      DisableConfirmComponent,
      TranslateModule.forChild() as any,
      ModalImageComponent,
      DateMaskDirective
   ],
   providers: [{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } }],
   schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
