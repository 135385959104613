<section>
   <div class="default-header">
      <h1>{{ title }}</h1>
      <button class="default-header__close" (click)="close()" id="closebutton">
         <span class="icon-cancel"></span>
      </button>
   </div>

   <ng-content></ng-content>
</section>
