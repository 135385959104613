import { Component, ViewEncapsulation } from "@angular/core";

@Component({
   selector: "transactions-table",
   templateUrl: "./transactions-table.component.html",
   styleUrls: ["./transactions-table.component.scss"],
   encapsulation: ViewEncapsulation.None,
})
export class TransactionsTableComponent {
   constructor() {}
}
