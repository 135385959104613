export interface NavMenuInterface {
   id: number;
   title: string;
   route: string;
   icon: string;
   collapseMenu: boolean;
   subRouting?: [
      {
         name: string;
         path: string;
      }
   ]
}

export const navMenuItemsPT: NavMenuInterface[] = [
   { id: 1, title: 'Painel', route: 'stats', icon: 'icon-home', collapseMenu: false },
   { id: 2, title: 'Usuários', route: 'users', icon: 'icon-users', collapseMenu: false },
   { id: 3, title: 'Agenda', route: 'schedule', icon: 'icon-calendar', collapseMenu: false, subRouting: [{name: 'Eventos Agenda', path: 'schedule/events'}] },
   { id: 4, title: 'Relatórios', route: 'reports', icon: 'icon-document', collapseMenu: false, subRouting: [{name: 'Geolocalização', path: 'reports/geolocalization'}] }
]

export const navMenuItemsEN: NavMenuInterface[] = [
   { id: 1, title: 'Dashboard', route: 'stats', icon: 'icon-home', collapseMenu: false },
   { id: 2, title: 'Users', route: 'users', icon: 'icon-users', collapseMenu: false },
   { id: 3, title: 'Schedule', route: 'schedule', icon: 'icon-calendar', collapseMenu: false, subRouting: [{name: 'Schedule Events', path: 'schedule/events'}]},
   { id: 4, title: 'Reports', route: 'reports/geolocalization', icon: 'icon-document', collapseMenu: false, subRouting: [{name: 'Geolocalization', path: 'reports/geolocalization'}] }
]
