<div *ngIf="calendarType === 'noTime'" [formGroup]="form" class="date-input">
   <label for="">{{ label }}</label>
   <p-calendar
      (onSelect)="onDateSelect($event)"
      placeholder="MM/DD/YYYY"
      [formControlName]="controlName"
      [minDate]="today && getToday"
      dateMask
   ></p-calendar>
   <small *ngIf="isRequiredInvalid" class="error-message">{{
      'ERRORS.REQUIRED' | translate
   }}</small>
</div>
<form *ngIf="calendarType === 'hasTime'" [formGroup]="form" class="date-input">
   <label for="">{{ label }}</label>
   <p-calendar
      (onSelect)="onDateSelect($event)"
      placeholder="YYYY/DD/MM"
      dateFormat="yy/mm/dd"
      [formControlName]="controlName"
      [showTime]="true"
      [showSeconds]="true"
   ></p-calendar>
   <small *ngIf="isRequiredInvalid" class="error-message">{{
      'ERRORS.REQUIRED' | translate
   }}</small>
</form>
<form *ngIf="calendarType === 'onlyTime'" [formGroup]="form" class="date-input">
   <label for="">{{ label }}</label>
   <p-calendar
      #calendarOnlyTime
      [formControlName]="controlName"
      [timeOnly]="true"
      [showTime]="true"
      [showSeconds]="true"
      hourFormat="24"
      placeholder="00:00:00"
      [showButtonBar]="true"
   >
      <ng-template pTemplate="footer">
         <button class="p-calendar__button btn-loader btn-primary" (click)="setTime()">
            Accept schedule
         </button>
      </ng-template>
   </p-calendar>
   <small *ngIf="isRequiredInvalid" class="error-message">{{
      'ERRORS.REQUIRED' | translate
   }}</small>
</form>
