import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { DialogRef } from "@shared/services/dialog/dialog-refs.helper";
import { DIALOG_DATA, ModalService } from "@shared/services/dialog/modal.service";
import * as moment from "moment";
import { ModalImageComponent } from "../modal-image/modal-image.component";

@Component({
   selector: "dialog-schedule",
   templateUrl: "./dialog-schedule.component.html",
   styleUrls: ["./dialog-schedule.component.scss"],
   encapsulation: ViewEncapsulation.None,
})
export class DialogScheduleComponent implements OnInit {
   constructor(
      public dialogRef: DialogRef, 
      @Inject(DIALOG_DATA) public data: any,
      private dialog: ModalService,
   ) { }
   public title = "Informações desta agenda";
   public buttonTitle = "Excluir";
   public weekDays = [
    {
       value: 'sunday',
       name: 'Sunday'
    },
    {
       value: 'monday',
       name: 'Monday'
    },
    {
       value: 'tuesday',
       name: 'Tuesday'
    },
    {
       value: 'wednesday',
       name: 'Wednesday'
    },
    {
       value: 'thursday',
       name: 'Thursday'
    },
    {
       value: 'friday',
       name: 'Friday'
    },
    {
       value: 'saturday',
       name: 'Saturday'
    }
   ]
   public activeDays: string[] = [];

   ngOnInit(): void {
      console.log(this.data)
   }

   convertDateApiToDisplay(date: string) {
    return moment(date).format("MM/DD/YYYY hh:mm:ss A")
   }

   getClass(day: string) {
    return this.data.weekdays.includes(day) ? 'day-active' : 'day-inactive';
   }

   openPhoto(photo: string){
      this.dialog
      .open(ModalImageComponent, {data: photo})
   }
}
