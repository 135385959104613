<ng-container *ngIf="type === 'default'; else noForm">
   <div class="kavalli-select">
      <span role="label" class="kavalli-select__label">{{ label }}</span>
      <mat-form-field
         (click)="animatedArrow()"
         [formGroup]="form"
         class="kavalli-select__field"
         appearance="outline"
      >
         <mat-select
            (selectionChange)="onOptionSelected($event)"
            disableOptionCentering
            [formControlName]="controlName"
            #select
         >
            <mat-option *ngFor="let option of dataOptions" [value]="option.id">
               {{ option.name }}
            </mat-option>
         </mat-select>
         <span *ngIf="showInformer" class="kavalli-select__field-placeholder">{{
            placeholder
         }}</span>
         <arrow
            class="kavalli-select__field-arrow"
            [type]="'bottom'"
            [active]="arrowToggle"
         ></arrow>
      </mat-form-field>
      <mat-error *ngIf="control?.touched && control?.hasError('required')"
         >*{{ 'COMPONENTS.ERRORS-LABEL.SELECT.FIELD-1' | translate }}</mat-error
      >
   </div>
</ng-container>

<ng-template #noForm>
   <div class="kavalli-select">
      <mat-form-field class="kavalli-select__field" appearance="outline" (click)="animatedArrow()">
         <mat-select #select (selectionChange)="onOptionSelected($event)">
            <mat-option *ngFor="let option of dataOptions" [value]="option.id">
               {{ option.name }}
            </mat-option>
         </mat-select>
         <span *ngIf="selectedValue && showInformer" class="kavalli-select__field-placeholder">
            {{ selectedValue.name }}
         </span>
         <arrow
            class="kavalli-select__field-arrow"
            [type]="'bottom'"
            [active]="arrowToggle"
         ></arrow>
      </mat-form-field>
   </div>
</ng-template>
