<pagination-template
   #p="paginationApi"
   [id]="id"
   [directionLinks]="true"
   (pageChange)="page.emit($event)"
>
   <div class="navigation-wrapper">
      <button class="navigation-wrapper__nav" (click)="p.previous()" [disabled]="p.isFirstPage()">
         {{ 'PAGINATION.PREVIOUS' | translate | uppercase }}
      </button>
      <span>
         <ng-container *ngFor="let page of p.pages">
            <button
               class="navigation-wrapper__page"
               [class.current]="p.getCurrent() === +page.label"
               (click)="p.setCurrent(+page.label)"
            >
               <p>{{ page.label }}</p>
            </button>
         </ng-container>
      </span>
      <button class="navigation-wrapper__nav" (click)="p.next()" [disabled]="p.isLastPage()">
         {{ 'PAGINATION.NEXT' | translate | uppercase }}
      </button>
   </div>
</pagination-template>
