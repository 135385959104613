/* istanbul ignore file */
import { HttpEvent } from "@angular/common/http";
import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FilesTypes } from "@enums/files-type.enum";
import {
   convertUnit,
   fileAccepted,
   maxFiles,
   maxSpliceFiles,
   uploadProgress,
   validateSize,
} from "@helpers/utils/file-handler.helper";
import { UploadService } from "@services/upload.service";
import { interval, Subscription } from "rxjs";
import { take } from "rxjs/operators";

@Component({
   selector: "drag-drop-file",
   templateUrl: "./drag-drop-file.component.html",
   styleUrls: ["./drag-drop-file.component.scss"],
})
export class DragDropFileComponent implements OnDestroy {
   @Input() label: string;
   @Input() cardLabel = "arraste para carregar seus arquivos";
   @Input() accept: string = FilesTypes.img_document;
   @Input() multiple = 0;
   @Input() key: string;

   @Input() autoupload = true;
   @Output() setFile = new EventEmitter<any>();
   @Output() preview = new EventEmitter<any>();

   public fileInfo: string | null;
   public progress = 0;
   public subscriptions: Subscription[] = [];
   public hasError = false;
   public images: any = [];
   public fileTypes: string[] = [];
   public maxItems = true;
   public pre_maxItems = true;
   public items: any[] = [];
   public diferentTypes = false;
   public typeError = false;
   constructor(private uploadService: UploadService) {}

   /* istanbul ignore next */
   ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe());
   }

   /* istanbul ignore next */
   fileDropped(event: any) {
      let files = [];
      files = event.target.files;
      const valid = this.checkNumberOfItems(files);

      this.pre_maxItems = valid;
      const previews: string[] = [];

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
         const file = files[i];
         this.fileTypes.push(file.type.split("/")[0] === "image" ? "image" : "document");
         const size = convertUnit(file.size);
         this.hasError = !validateSize(file.size, "2mb");
         this.typeError = !fileAccepted(file, this.accept);
         if (this.hasError || this.typeError) {
            return;
         }
         this.fileInfo = this.multiple > 0 ? "carregando" : `${file.name} (${size})`;
         previews.push(URL.createObjectURL(file));
         this.images.push(URL.createObjectURL(file));

         formData.append(this.key + i, file);

         this.diferentTypes = !this.fileTypes.every(elem => elem === "image");

         if (this.autoupload && this.diferentTypes) {
            this.uploadFile(formData, this.fileTypes[i]);
         }
      }
      this.singleAutoUpload(files, formData);
   }

   /* istanbul ignore next */
   private singleAutoUpload(files: any, formData: any) {
      if (!this.autoupload) {
         this.loadFifle(files);
      } else if (!this.diferentTypes) {
         this.uploadFile(formData, this.fileTypes[0]);
      }
   }

   /* istanbul ignore next */
   private checkNumberOfItems(files: any): any {
      for (let i = 0; i < files.length; i++) {
         this.items.push(files[i]);
      }
      return maxFiles(this.items, 5);
   }

   /* istanbul ignore next */
   private uploadFile(formData: any, type: string) {
      this.subscriptions.push(
         this.uploadService
            .uploadFiles(formData, type)
            .pipe()
            .subscribe({
               next: (event: HttpEvent<any>) => {
                  const { progress, res } = uploadProgress(event);
                  this.progress = progress;
                  if (this.multiple && this.progress === 100) {
                     this.fileInfo = "";
                  }

                  if(progress === 100){
                     this.setFile.emit(res);
                  }
               },
               error: error => {
                  console.error("UPLOAD", error);
               },
            }),
      );
   }

   /* istanbul ignore next */
   private loadFifle(files: any) {
      const numbers = interval(150);
      const takeFourNumbers = numbers.pipe(take(11));
      this.subscriptions.push(
         takeFourNumbers.subscribe(x => {
            this.progress = x * 10;
            if (this.multiple && this.progress === 100) {
               this.fileInfo = "";
            }

            if (this.progress === 100) {
               this.preview.emit(maxSpliceFiles(this.images, 5));
               this.setFile.emit(files);
               this.maxItems = this.pre_maxItems;
            }
         }),
      );
   }
}
