<div class="privacy-policy">
   <h1>Privacy Policy</h1>
   <p>Last updated: 03/20/2024</p>
   <p>Your privacy is important to us. It is Woorky's policy to respect your privacy regarding any information we may collect while operating the Woorky app. We request personal information only when we truly need it to provide a service. We do so by fair and lawful means, with your knowledge and consent. We also inform you why we are collecting it and how it will be used.</p>
   <p>We retain collected information only for as long as necessary to provide the requested service. When we store data, we protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
   <p>We do not share personally identifiable information publicly or with third parties, except when required by law.</p>
   <p>Our app may contain links to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility for their respective privacy policies.</p>
   <p>You are free to refuse our request for personal information, understanding that we may not be able to provide some of the desired services.</p>
   <p>Your continued use of our app will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, please contact us.</p>
 </div>
 