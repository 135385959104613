import { Component, ViewEncapsulation } from "@angular/core";

@Component({
   selector: "kavalli-table-v1",
   templateUrl: "./table.component.html",
   styleUrls: ["./table.component.scss"],
   encapsulation: ViewEncapsulation.None,
})
export class TableComponent {
   public currentPage = 1;
   constructor() {}
}
