<div class="tags">
   <div class="tags-content">
      <div class="tags-content__inner" [style.background]="'#fff'">
         <div class="tags-content__inner-content">
            <div class="tags-content__inner-content-data">
               <p class="value">{{ dataTags.todaySchedules }}</p>
               <p class="title">{{ 'TAGS.SCHEDULES-TODAY' | translate }}</p>
            </div>
            <img width="40" height="40" src="assets/imgs/schedule-time.svg" alt="Icon" />
         </div>
      </div>
      <div class="tags-content__inner" [style.background]="'#fff'">
         <div class="tags-content__inner-content">
            <div class="tags-content__inner-content-data">
               <p class="value">{{ dataTags.totalUsers }}</p>
               <p class="title">{{ 'TAGS.TOTAL-USERS' | translate }}</p>
            </div>
            <img width="40" height="40" src="assets/imgs/total-users.svg" alt="Icon" />
         </div>
      </div>
      <div class="tags-content__inner" [style.background]="'#fff'">
         <div class="tags-content__inner-content">
            <div class="tags-content__inner-content-data">
               <p class="value">{{ dataTags.totalUsersOnline }}</p>
               <p class="title">{{ 'TAGS.USERS-ON' | translate }}</p>
            </div>
            <img width="40" height="40" src="assets/imgs/users-online.svg" alt="Icon" />
         </div>
      </div>
   </div>
</div>
